import { template as template_e071da8c6e0542c590942dc84efc0dab } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_e071da8c6e0542c590942dc84efc0dab(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
