import { template as template_0207029026184c338b6ff3cef24051f3 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0207029026184c338b6ff3cef24051f3(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
